import { DepositAccountType } from '@/bundle/_OrgAdmin/pages/InboundWire/types/depositAccount';
import { authApiService } from '@/api/authApiService';
import { ApiResponseType } from '@/types/sharedTypes';

export type UpdateDepositAccountStatusDataType = {
  status: string;
};

export const updateDepositAccountStatusApi = async (
  depositAccountId: string,
  data: UpdateDepositAccountStatusDataType
): Promise<ApiResponseType<DepositAccountType>> => {
  const uri = `/organization/deposit-accounts/${depositAccountId}/status/`;

  return authApiService.PATCH(uri, data);
};
