import styled from 'styled-components';
import { Tooltip } from 'react-tooltip';
import { COLORS } from '@/styles/colors';
import { bodySmall, caption, poppinsRegularFont } from '@/styles/typography';

export const StyledTooltip = styled(Tooltip).attrs({
  className: 'wv-tooltip',
})<{ type: 'tooltip' | 'hint' }>`
  --rt-color-dark: ${COLORS.grey[850]};
  --rt-opacity: 1;

  &.wv-tooltip {
    padding: 8px 10px;
    border-radius: 8px;
    color: ${COLORS.white};
    ${poppinsRegularFont};
    ${bodySmall};
    z-index: 100;

    ${({ type }) =>
      type === 'hint' &&
      `
        ${caption};
        padding: 6px 8px;
        border-radius: 6px;
    `}
  }
`;
