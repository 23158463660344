import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import {
  AMOUNT_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  STRING_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import { ReactComponent as CalendarIcon } from '../../../../../../images/CalendarIcon.svg';
import { WIRE_TYPE_SINGULAR_LABEL } from '@/const/wire';

const INBOUND_WIRE_OPTION = { value: 'inbound', label: WIRE_TYPE_SINGULAR_LABEL.inbound };
const OUTBOUND_WIRE_OPTION = { value: 'outbound', label: WIRE_TYPE_SINGULAR_LABEL.outbound };
const WIRES_OPTIONS = [INBOUND_WIRE_OPTION, OUTBOUND_WIRE_OPTION];

export const getCreateWireFormConfig = (currencyOptions, selectedWireType): FormConfigType => {
  const selectedWireOption = WIRES_OPTIONS.find((item) => item.value === selectedWireType);

  return [
    {
      name: 'type',
      label: 'Wire Type*',
      placeholder: 'Select Wire Type',
      type: 'select',
      options: WIRES_OPTIONS,
      value: selectedWireOption,
      validator: REQUIRED_OBJECT_VALIDATOR,
    },

    {
      name: 'name',
      label: 'Wire Name*',
      placeholder: 'Enter Name',
      validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    },
    {
      name: 'amount',
      label: 'Wire Amount',
      placeholder: 'Enter Amount',
      column: 7,
      validator: AMOUNT_VALIDATOR,
    },
    {
      name: 'currency',
      label: 'Currency',
      type: 'select',
      options: currencyOptions,
      value: currencyOptions[0],
      column: 5,
      validator: REQUIRED_OBJECT_VALIDATOR,
    },
    {
      name: 'expected_payment_date',
      label: 'Expected Payment Date',
      type: 'datepicker',
      placeholder: 'Select Date',
      column: 7,
      meta: {
        hasErrorComponent: true,
      },
    },
    {
      name: 'notes',
      label: 'Notes',
      type: 'textarea',
      placeholder: 'Enter Notes',
      validator: TEXT_FIELD_VALIDATOR,
    },
  ];
};

export const getOutboundWireEditConfig = (currentOptions = []): FormConfigType => {
  return [
    {
      name: 'name',
      label: 'Wire Name*',
      placeholder: 'Enter Name',
      validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    },
    {
      name: 'ref_id',
      label: 'Wire ID',
      placeholder: 'Enter Wire ID',
      disabled: true,
    },
    {
      name: 'type',
      label: 'Wire Type',
      disabled: true,
    },
    {
      name: 'date_created',
      label: 'Wire Creation Date',
      column: 6,
      disabled: true,
      meta: {
        iconRight: <CalendarIcon />,
      },
    },
    {
      name: 'expected_payment_date',
      label: 'Expected Payment Date',
      type: 'datepicker',
      placeholder: 'Select Date',
      column: 6,
      validator: STRING_VALIDATOR,
    },
    {
      name: 'amount',
      label: 'Wire Amount',
      placeholder: 'Enter Amount',
      column: 6,
      validator: AMOUNT_VALIDATOR,
    },
    {
      name: 'currency',
      label: 'Currency',
      type: 'select',
      options: currentOptions,
      column: 6,
      validator: REQUIRED_OBJECT_VALIDATOR,
    },
    {
      name: 'notes',
      label: 'Notes',
      type: 'textarea',
      placeholder: 'Enter Notes',
      validator: TEXT_FIELD_VALIDATOR,
    },
  ];
};
