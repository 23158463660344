import { getRecipientsWiresApi } from '@/api/v1/recipient/getRecipientsWiresApi';
import { prepareSearchNameListApiParams } from '@/bundle/shared/helpers/apiParamsHelpers';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const loadRecipientWires = async (queryParams) => {
  const apiParams = prepareSearchNameListApiParams(queryParams);

  try {
    const response = await getRecipientsWiresApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
