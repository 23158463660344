import { parseISO } from 'date-fns';
import { WireType } from '@/types/wireTypes';
import { formatDate } from '@/helpers/dateHelpers/dateHelpers';
import { DEFAULT_DATE_FORMAT } from '@/helpers/dateHelpers/const';
import { WIRE_TYPE_SINGULAR_LABEL } from '@/const/wire';

export const formatInboundWire = (inboundWire = {} as WireType) => {
  const { name, ref_id, type, date_created, expected_payment_date, amount, currency, notes } = inboundWire;

  return {
    name,
    ref_id,
    type: WIRE_TYPE_SINGULAR_LABEL[type],
    date_created: formatDate(date_created, DEFAULT_DATE_FORMAT),
    expected_payment_date: expected_payment_date ? parseISO(expected_payment_date) : expected_payment_date,
    amount,
    currency,
    notes,
  };
};
