import { ReactElement, ReactNode } from 'react';
import { NoResultsWrapper, NoResultsIcon, NoResultsMessage } from './styles';

type NoResultsType = {
  icon: ReactElement;
  message: ReactNode;
  marginTop?: number;
};

export const NoResults = ({ marginTop = 120, icon, message }: NoResultsType) => {
  return (
    <NoResultsWrapper marginTop={marginTop}>
      <NoResultsIcon>{icon}</NoResultsIcon>
      <NoResultsMessage>{message}</NoResultsMessage>
    </NoResultsWrapper>
  );
};
