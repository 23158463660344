import { SearchParamsType, useSearch } from '@/bundle/shared/hooks/useSearch';
import { useGetSearchParams } from '@/hooks/useGetSearchParams';
import {
  initSearchFilters,
  SEARCH_EMAIL_TYPES_LIST,
  SearchEmailType,
  SearchFiltersType,
} from '../ui/TransactionsSearchFilters/const';
import { useReplaceSearchParams } from '@/hooks/useReplaceSearchParams';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  SEARCH_WIRE_TYPES_LIST,
  WIRE_TYPE_ALL,
  WireFilterType,
  WireTypesType,
} from '../pages/Transactions/_Details/const/const';

export type FiltersSearchParams = {
  types?: SearchEmailType[];
  type?: WireFilterType;
};

const getFilterWireType = (wireType: WireTypesType) => {
  return wireType !== WIRE_TYPE_ALL ? wireType : null;
};

export const useFilterSearch = () => {
  const location = useLocation();
  const search = useSearch();
  const searchParams = useGetSearchParams<FiltersSearchParams>();
  const { replaceSearchParams } = useReplaceSearchParams<FiltersSearchParams & SearchParamsType>();

  const [searchFilters, setSearchFilters] = useState<SearchFiltersType>(initSearchFilters);

  const changeSearchFilters = (filters: SearchFiltersType) => {
    const wireType = getFilterWireType(filters.type);

    setSearchFilters(filters);
    replaceSearchParams({
      types: filters.byName ? null : filters.searchEmailTypes,
      name: search.search,
      type: wireType,
      page: null,
    });
  };

  useEffect(() => {
    const types = searchParams?.types;
    const existingWireType =
      SEARCH_WIRE_TYPES_LIST.find((wireType) => wireType.toLowerCase() === searchParams?.type?.toLowerCase()) ||
      WIRE_TYPE_ALL;

    if (types?.length > 0) {
      const emailFilters = types.filter((type) =>
        SEARCH_EMAIL_TYPES_LIST.some((item) => item.toLowerCase() === type.toLowerCase())
      );

      if (emailFilters.length > 0) {
        setSearchFilters({
          byName: false,
          searchEmailTypes: emailFilters,
          type: existingWireType,
        });

        return;
      }
    }

    setSearchFilters({ ...initSearchFilters, type: existingWireType });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {
    ...search,
    searchFilters,
    changeSearchFilters,
  };
};
