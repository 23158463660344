import { NoResults } from '@/components/NoResults/NoResults';
import { LoadPaymentDetailsLoading } from '../../../../OutboundWire/_Details/ui/LoadPaymentDetailsWidget/LoadPaymentDetailsLoading';
import { PaymentDetails, PaymentDetailsWrapper } from './styles';
import { ReactComponent as NoPaymentDetailsIcon } from '../../../../../../../images/NoItemsIcon.svg';
import { Widget } from '@/components/Widget/Widget';
import { DetailsBlockGenerator } from '@/components/DetailsBlockGenerator/DetailsBlockGenerator';
import {
  DOMESTIC_PAYMENT_DETAILS_CONFIG,
  INTERNATIONAL_PAYMENT_DETAILS_CONFIG,
} from '../../hooks/useDepositAccountPaymentDetailsConfig';
import { Notification } from '@/components/Notification/Notification';
import { getContactUsPath } from '@/bundle/pages/ContactUsPage/urls/getContactUsUrl';
import { ContactUsLink } from '@/components/Widget/ui/ErrorHint/styles';
import { IntermediaryBankDetails } from '@/bundle/shared/components/IntermediaryBankDetails/IntermediaryBankDetails';
import { DEPOSIT_ACCOUNT_STATUS_DB } from '@/const/wire';
import { isDomesticPaymentType, isInternationalPaymentType } from '@/helpers/paymentDetailsHelpers';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { ResponseErrorType } from '@/types/sharedTypes';

type DepositAccountPaymentDetailsType = {
  status: string;
  paymentDetails: PaymentDetailsGeneralType;
  isLoading: boolean;
  error?: ResponseErrorType;
};

export const DepositAccountPaymentDetails = ({
  status,
  paymentDetails,
  isLoading,
  error,
}: DepositAccountPaymentDetailsType) => {
  const blockchainPaymentDetails = paymentDetails?.blockchain_payment_details_data;
  const internalPaymentDetails = paymentDetails?.internal_payment_details_data;
  const isDataFromBlockchain = paymentDetails?.is_blockchain_payment_details_data_from_bc;

  const isDomesticPayment = isDomesticPaymentType(internalPaymentDetails?.payment_type);
  const isInternationalPayment = isInternationalPaymentType(internalPaymentDetails?.payment_type);

  const isStatusFailed = status === DEPOSIT_ACCOUNT_STATUS_DB.FAILED;
  const isStatusPending = status === DEPOSIT_ACCOUNT_STATUS_DB.PENDING;
  const hasPaymentDetails =
    status === DEPOSIT_ACCOUNT_STATUS_DB.ACTIVE || status === DEPOSIT_ACCOUNT_STATUS_DB.INACTIVE;
  const hasDetails = !isLoading && !error;
  const hasBlockchainDetails = hasDetails && isDataFromBlockchain;
  const hasDatabaseDetails = hasDetails && isDataFromBlockchain === false;

  const PAYMENT_DETAILS = isDomesticPayment
    ? DOMESTIC_PAYMENT_DETAILS_CONFIG(paymentDetails)
    : INTERNATIONAL_PAYMENT_DETAILS_CONFIG(paymentDetails);
  const hasIntermediaryBank =
    (isDomesticPayment && !!blockchainPaymentDetails?.intermediary_bank_aba_number) ||
    (isInternationalPayment && !!blockchainPaymentDetails?.intermediary_bank_swift_code);

  if (isStatusPending) {
    return (
      <NoResults
        icon={<NoPaymentDetailsIcon />}
        message={'This deposit account has been submitted to the WireVault blockchain.'}
        marginTop={84}
      />
    );
  }

  return (
    <PaymentDetailsWrapper>
      <Widget title='Wire Info'>
        {isLoading && <LoadPaymentDetailsLoading />}

        {isStatusFailed && (
          <Notification variant='error' fontVariant='medium' verticalAlign='center'>
            Something went wrong. Please contact&nbsp;
            <ContactUsLink to={getContactUsPath()}>WireVault team</ContactUsLink>
          </Notification>
        )}

        {hasPaymentDetails && hasBlockchainDetails && (
          <Notification variant='success' fontVariant='medium' verticalAlign='center' mb='20px'>
            Stored in the WireVault blockchain.
          </Notification>
        )}

        {hasPaymentDetails && hasDatabaseDetails && (
          <Notification variant='info-secondary' mb='20px'>
            The WireVault blockchain is unavailable. The wire info cannot be confirmed at this time. Please try again
            later.
          </Notification>
        )}

        {hasPaymentDetails && (
          <PaymentDetails>
            {PAYMENT_DETAILS && <DetailsBlockGenerator configuration={PAYMENT_DETAILS} />}
            {hasIntermediaryBank && <IntermediaryBankDetails paymentDetails={paymentDetails} />}
          </PaymentDetails>
        )}
      </Widget>
    </PaymentDetailsWrapper>
  );
};
