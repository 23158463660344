import { COLORS } from '@/styles/colors';
import { body, bodySmall, poppinsRegularFont } from '@/styles/typography';
import styled from 'styled-components';
import { flex } from '@/styles/layout';

export type InputSizeVariant = 'small' | 'medium';

export const CustomInput = styled.input<{
  variant?: InputSizeVariant;
}>`
  min-width: 0;
  flex: 1;
  outline: none;
  display: block;
  color: ${COLORS.black};
  ${poppinsRegularFont};
  border: none;
  ${body};

  background: transparent;

  &[type='password'] {
    font-size: 22px;
    letter-spacing: 1px;
  }

  &:placeholder-shown {
    ${body};
  }

  ::placeholder {
    ${body};
    color: ${COLORS.grey[600]};
    font-family: ${poppinsRegularFont};
  }

  :input-placeholder,
  ::input-placeholder {
    ${body};
    color: ${COLORS.grey[600]};
    font-family: ${poppinsRegularFont};
  }

  ${({ variant }) =>
    variant === 'small' &&
    `
    ${bodySmall};

    &:placeholder-shown {
    ${bodySmall};
    }

    ::placeholder {
    ${bodySmall};
    }

    :input-placeholder,
    ::input-placeholder {
    ${bodySmall};
    }
  `};
`;

export const BeforeAfterWrapper = styled.div`
  ${flex({ alignItems: 'center' })};
`;

export const IconSearchWrapper = styled(BeforeAfterWrapper)`
  cursor: pointer;
  &:not(:hover) {
    display: none;
  }
`;

export const InputWrapper = styled.div<{
  hasError?: boolean;
  variant?: InputSizeVariant;
  hasIconLeft?: boolean;
  hasClearButton?: boolean;
  disabled?: boolean;
}>`
  ${flex()}
  width: 100%;
  height: 50px;
  column-gap: 8px;
  border: ${({ hasError }) => (hasError ? `1px solid ${COLORS.red[600]}` : `1px solid ${COLORS.grey[400]}`)};
  padding: 0 16px;
  border-radius: 10px;
  background: ${COLORS.grey[250]};
  overflow: hidden;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    border: 1px solid ${COLORS.grey[300]};
    background-color: ${COLORS.grey[200]};
  `}

  ${({ variant }) =>
    variant === 'small' &&
    `
      height: 40px;
    `}


  ${CustomInput}:focus ~ ${IconSearchWrapper} {
    ${flex()}
  }

  &:focus-within {
    border: 1px solid ${COLORS.green[500]};
    box-shadow: 0 0 2px rgb(0 134 111 / 60%);
    background: ${COLORS.white};
    color: #031020;
  }

  &:hover:not(:focus-within) {
    border: 1px solid ${COLORS.grey[700]};
    background: ${COLORS.grey[250]};

    ${IconSearchWrapper} {
      ${flex()}
    }
  }
`;
