import { ChangeEvent, ComponentPropsWithoutRef, FormEvent, ReactNode, useRef, useState } from 'react';
import { Input } from '../../../../components/form/fields/InputNew/Input';
import { InputSizeVariant } from '../../../../components/form/fields/InputNew/styles';
import { ReactComponent as SearchIcon } from './images/search-icon.svg';
import { ReactComponent as FiltersIcon } from './images/filters-icon.svg';
import { FiltersModalWrapper, IconFiltersWrapper, SearchInputWrapper } from './styles';
import { useOutsideClick } from '@/hooks/useOutsideClick';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { TEXT_MAX_LENGTH } from '@/helpers/formHelpers/validators';

type SearchInputType = {
  variant?: InputSizeVariant;
  filters?: ReactNode;
  onClear: () => void;
  onChange: (value: string, event?: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
} & ComponentPropsWithoutRef<'input'>;

export const SearchInput = ({ variant = 'small', filters, onChange, onSubmit, ...props }: SearchInputType) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [isOpenFiltersModal, setIsOpenFiltersModal] = useState(false);

  useOutsideClick(wrapperRef, () => setIsOpenFiltersModal(false));

  const changeValue = (event: ChangeEvent<HTMLInputElement>) => {
    const eventValue = event.target.value;

    // When next value is empty - trigger the onClear event
    if (props.value && !eventValue) {
      props.onClear();
    }

    onChange(eventValue, event);
  };

  const submitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    onSubmit();
  };

  return (
    <SearchInputWrapper onSubmit={submitSearch}>
      <Input
        variant={variant}
        onChange={changeValue}
        before={<SearchIcon />}
        maxLength={TEXT_MAX_LENGTH}
        after={
          !!filters && (
            <IconFiltersWrapper
              data-tooltip-id='search-filters-tooltip'
              selected={isOpenFiltersModal}
              onClick={() => setIsOpenFiltersModal(!isOpenFiltersModal)}
            >
              <Tooltip id='search-filters-tooltip' type='hint'>
                Search filters
              </Tooltip>
              <FiltersIcon />
            </IconFiltersWrapper>
          )
        }
        {...props}
      />

      {!!filters && isOpenFiltersModal && <FiltersModalWrapper ref={wrapperRef}>{filters}</FiltersModalWrapper>}
    </SearchInputWrapper>
  );
};
