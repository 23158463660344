import { CustomLabel, FakeRadio, Input, RadioTitle } from './styles';
import { ChangeEvent } from 'react';

interface RadioFieldType {
  label: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const RadioField = ({ label, disabled = false, checked, onChange }: RadioFieldType) => {
  return (
    <CustomLabel disabled={disabled}>
      <Input type='radio' checked={checked} onChange={onChange} disabled={disabled} />
      <FakeRadio disabled={disabled} />
      <RadioTitle isActive>{label}</RadioTitle>
    </CustomLabel>
  );
};
