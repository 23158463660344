import { COLORS } from '@/styles/colors';
import { flex } from '@/styles/layout';
import styled from 'styled-components';

export const TransactionsFiltersWrapper = styled.div`
  padding: 4px 0;
`;

export const TransactionsFiltersRow = styled.label<{ checked?: boolean }>`
  ${flex({ flexDirection: 'column', justifyContent: 'center' })}
  padding: 10px 16px;
  height: 44px;

  ${({ checked }) =>
    checked &&
    `
    background: ${COLORS.grey[150]}
  `}
`;
