import { WireTypesType } from '../../pages/Transactions/_Details/const/const';

export const RECIPIENT_EMAIL = 'recipient_user_email';
export const RECIPIENT_PROXY_EMAIL = 'recipient_proxy_user_email';
export const DEPOSITOR_EMAIL = 'depositor_user_email';

export type SearchEmailType = typeof RECIPIENT_EMAIL | typeof RECIPIENT_PROXY_EMAIL | typeof DEPOSITOR_EMAIL;

export const SEARCH_EMAIL_TYPES_LIST: SearchEmailType[] = [RECIPIENT_EMAIL, RECIPIENT_PROXY_EMAIL, DEPOSITOR_EMAIL];

export type SearchFiltersType = {
  byName?: boolean;
  searchEmailTypes?: SearchEmailType[];
  type?: WireTypesType;
};

type EmailTypeOptions = {
  type: SearchEmailType;
  label: string;
};

export const SEARCH_EMAIL_TYPE_OPTIONS: EmailTypeOptions[] = [
  {
    type: RECIPIENT_EMAIL,
    label: 'Recipient',
  },
  {
    type: RECIPIENT_PROXY_EMAIL,
    label: 'Recipient Proxy',
  },
  {
    type: DEPOSITOR_EMAIL,
    label: 'Depositor',
  },
];

export const initSearchFilters: SearchFiltersType = {
  byName: true,
  searchEmailTypes: [],
};

export const SEARCH_FILTER_RADIO_OPTION_TYPE = {
  NAME: 'name',
  EMAIL: 'email',
} as const;
