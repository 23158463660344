import { getOutboundWireDetailsUrl } from '@/bundle/_OrgAdmin/pages/OutboundWire/_Details/urls/getOutboundWireDetailsUrl';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';
import { getRecipientWireDetailsUrl } from '@/bundle/_Recipient/_Details/urls/getRecipientWireDetailsUrl';
import { NavigationLink } from '@/components/NavigationLink/NavigationLink';
import { Status } from '@/components/Status/Status';
import { ErrorHint } from '@/components/Widget/ui/ErrorHint/ErrorHint';
import { WIRE_STATUS_DB, WIRE_TYPE_SINGULAR_LABEL } from '@/const/wire';
import { useUser } from '@/context/userContext';
import { dateFormatter, formatAmount, formatBlockchainId, phoneFormatter } from '@/helpers/formatHelpers';
import { isDomesticPaymentType, isInternationalPaymentType } from '@/helpers/paymentDetailsHelpers';
import {
  isCreatedWireStatus,
  isPaymentDetailsProvidedWireStatus,
  isRecipientAssignedWireStatus,
  isRecipientInvitedWireStatus,
  isRecipientProxy,
  isRecipientProxyAssignedWireStatus,
  isRecipientProxyInvitedWireStatus,
} from '@/helpers/wireHelpers';
import { GRADIENTS } from '@/styles/gradients';
import { PaymentDetailsGeneralType } from '@/types/paymentDetailsTypes';
import { AssignedWireType, WireStatusType } from '@/types/wireTypes';
import { useParams } from 'react-router-dom';

export const getHasIntermediaryBank = (paymentDetails: PaymentDetailsGeneralType): boolean => {
  if (!paymentDetails) return;

  const { internal_payment_details_data, blockchain_payment_details_data } = paymentDetails;
  const isDomesticPayment = isDomesticPaymentType(internal_payment_details_data?.payment_type);
  const isInternationalPayment = isInternationalPaymentType(internal_payment_details_data?.payment_type);

  return (
    (isDomesticPayment && !!blockchain_payment_details_data?.intermediary_bank_aba_number.length) ||
    (isInternationalPayment && !!blockchain_payment_details_data?.intermediary_bank_swift_code.length)
  );
};

const getDomesticPaymentDetailsConfig = (paymentDetails: PaymentDetailsGeneralType) => {
  if (!paymentDetails) return {};

  const {
    blockchain_id,
    internal_payment_details_data,
    blockchain_payment_details_data,
    is_blockchain_payment_details_data_from_bc,
  } = paymentDetails;
  const { recipient_name, recipient_address, account_name, bank_name, bank_address, bank_phone_number } =
    internal_payment_details_data || {};

  return {
    blockchainId: {
      label: 'Blockchain ID',
      value: formatBlockchainId(blockchain_id),
      title: blockchain_id || '-',
    },
    recipient: {
      label: 'Recipient',
      value: recipient_name || '-',
    },
    recipientAddress: {
      label: 'Recipient Address',
      value: recipient_address || '-',
    },
    accountName: {
      label: 'Account Name',
      value: account_name || '-',
    },
    abaNumber: {
      label: 'Routing / ABA Number',
      value: blockchain_payment_details_data?.bank_aba_number || '-',
      gradient: is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    bankName: {
      label: 'Bank Name',
      value: bank_name || '-',
    },
    accountNumber: {
      label: 'Account Number',
      value: blockchain_payment_details_data?.bank_account_number || '-',
      gradient: is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    bankAddress: {
      label: 'Bank Address',
      value: bank_address || '-',
    },
    bankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(bank_phone_number),
    },
  };
};

export const getDomesticPaymentDetailsIntermediaryBankConfig = (paymentDetails: PaymentDetailsGeneralType) => {
  if (!paymentDetails) return {};

  const { blockchain_payment_details_data, is_blockchain_payment_details_data_from_bc, internal_payment_details_data } =
    paymentDetails;

  return {
    intermediaryBankAbaNumber: {
      label: 'Routing / ABA Number',
      value: blockchain_payment_details_data?.intermediary_bank_aba_number || '-',
      gradient: is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    intermediaryBankName: {
      label: 'Bank Name',
      value: internal_payment_details_data?.intermediary_bank_name || '-',
    },
    intermediaryBankAccountNumber: {
      label: 'Account Number',
      value: blockchain_payment_details_data?.intermediary_bank_account_number || '-',
      gradient: is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    intermediaryBankAddress: {
      label: 'Bank Address',
      value: internal_payment_details_data?.intermediary_bank_address || '-',
    },
    intermediaryBankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(internal_payment_details_data?.intermediary_bank_phone_number),
    },
  };
};

export const getInternationalPaymentDetailsConfig = (paymentDetails: PaymentDetailsGeneralType) => {
  if (!paymentDetails) return {};

  const {
    blockchain_id,
    internal_payment_details_data,
    blockchain_payment_details_data,
    is_blockchain_payment_details_data_from_bc,
  } = paymentDetails;
  const { recipient_name, recipient_address, account_name, bank_name, bank_phone_number } =
    internal_payment_details_data;

  return {
    blockchainId: {
      label: 'Blockchain ID',
      value: formatBlockchainId(blockchain_id),
      title: blockchain_id || '-',
    },
    recipient: {
      label: 'Recipient',
      value: recipient_name || '-',
    },
    recipientAddress: {
      label: 'Recipient Address',
      value: recipient_address || '-',
    },
    accountName: {
      label: 'Account Name',
      value: account_name || '-',
    },
    swiftCode: {
      label: 'Swift Code',
      value: blockchain_payment_details_data?.bank_swift_code || '-',
      gradient: is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    bankName: {
      label: 'Bank Name',
      value: bank_name || '-',
    },
    accountNumber: {
      label: 'Account Number',
      value: blockchain_payment_details_data?.bank_account_number || '-',
      gradient: is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    bankAddress: {
      label: 'Bank Address',
      value: internal_payment_details_data?.bank_address || '-',
    },
    bankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(bank_phone_number),
    },
  };
};

export const getInternationalPaymentDetailsIntermediaryBankConfig = (paymentDetails: PaymentDetailsGeneralType) => {
  if (!paymentDetails) return {};

  const { blockchain_payment_details_data, is_blockchain_payment_details_data_from_bc, internal_payment_details_data } =
    paymentDetails;
  const { intermediary_bank_swift_code, intermediary_bank_account_number } = blockchain_payment_details_data || {};
  const { intermediary_bank_name, intermediary_bank_address, intermediary_bank_phone_number } =
    internal_payment_details_data || {};

  return {
    intermediaryBankAbaNumber: {
      label: 'Swift Code',
      value: intermediary_bank_swift_code || '-',
      gradient: is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    intermediaryBankName: {
      label: 'Bank Name',
      value: intermediary_bank_name || '-',
    },
    intermediaryBankAccountNumber: {
      label: 'Account Number',
      value: intermediary_bank_account_number || '-',
      gradient: is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    intermediaryBankAddress: {
      label: 'Bank Address',
      value: intermediary_bank_address || '-',
    },
    intermediaryBankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(intermediary_bank_phone_number),
    },
  };
};

export const getPaymentDetailsConfig = (paymentDetails: PaymentDetailsGeneralType) => {
  if (!paymentDetails) return {};

  const isDomesticPayment = isDomesticPaymentType(paymentDetails?.internal_payment_details_data?.payment_type);

  return isDomesticPayment
    ? getDomesticPaymentDetailsConfig(paymentDetails)
    : getInternationalPaymentDetailsConfig(paymentDetails);
};

export const getPaymentDetailsIntermediaryBankConfig = (paymentDetails: PaymentDetailsGeneralType) => {
  if (!paymentDetails) return {};

  const isDomesticPayment = isDomesticPaymentType(paymentDetails?.internal_payment_details_data?.payment_type);

  return isDomesticPayment
    ? getDomesticPaymentDetailsIntermediaryBankConfig(paymentDetails)
    : getInternationalPaymentDetailsIntermediaryBankConfig(paymentDetails);
};

export const getRecipientDetailsConfig = (wire: AssignedWireType) => {
  if (!wire) return {};

  const isProxy = isRecipientProxy(wire);
  const userDetails = isProxy ? wire?.assigned_proxy_user : wire?.assigned_user;

  if (!userDetails) return {};

  const { first_name, middle_name, last_name, email, mobile_phone } = userDetails;

  return {
    firstName: {
      label: 'First Name',
      value: first_name || '-',
    },
    middleName: {
      label: 'Middle Name',
      value: middle_name || '-',
    },
    lastName: {
      label: 'Last Name',
      value: last_name || '-',
    },
    email: {
      label: 'Email Address',
      value: email || '-',
    },
    mobile_phone: {
      label: 'Mobile Phone Number',
      value: phoneFormatter(mobile_phone),
    },
  };
};

export const getExecutorDetailsConfig = (wire: AssignedWireType) => {
  if (!wire) return {};

  const userDetails = wire?.assigned_executor?.user;

  if (!userDetails) return {};

  const { first_name, middle_name, last_name, email, mobile_phone } = userDetails;

  return {
    firstName: {
      label: 'First Name',
      value: first_name || '-',
    },
    middleName: {
      label: 'Middle Name',
      value: middle_name || '-',
    },
    lastName: {
      label: 'Last Name',
      value: last_name || '-',
    },
    email: {
      label: 'Email Address',
      value: email || '-',
    },
    mobile_phone: {
      label: 'Mobile Phone Number',
      value: phoneFormatter(mobile_phone),
    },
  };
};

const isEditableWire = (wireStatus: WireStatusType) => {
  return (
    isCreatedWireStatus(wireStatus) ||
    isRecipientInvitedWireStatus(wireStatus) ||
    isRecipientAssignedWireStatus(wireStatus) ||
    isRecipientProxyInvitedWireStatus(wireStatus) ||
    isRecipientProxyAssignedWireStatus(wireStatus) ||
    isPaymentDetailsProvidedWireStatus(wireStatus)
  );
};

export const useWireGeneralDetailsConfig = (wire: AssignedWireType) => {
  const { isOrgAdminRole, isExecutorRole } = useUser();
  const { transactionId } = useParams<{ transactionId: string }>();

  if (!wire) return {};

  const {
    contract,
    name,
    status,
    type,
    amount,
    ref_id,
    date_created,
    expected_payment_date,
    created_by,
    notes,
    date_modified,
    child_wire,
    parent_wire,
  } = wire;
  const hasEditButton = isEditableWire(wire?.status) && isOrgAdminRole;

  return {
    transactionName: {
      label: 'Transaction Name',
      value: contract?.name || '-',
    },
    wireName: {
      type: 'component',
      label: 'Wire Name',
      value: name || '-',
      valueVariant: 'big',
      component: hasEditButton ? ({ onClick }: any) => <DetailsEditButton onEdit={onClick} /> : null,
    },
    errorHint: {
      type: 'hint',
      label: 'Wire failed status',
      component: status === WIRE_STATUS_DB.FAILED ? () => <ErrorHint /> : null,
    },
    type: {
      label: 'Type',
      value: WIRE_TYPE_SINGULAR_LABEL[type],
    },
    status: {
      type: 'component',
      label: 'Status',
      component: () => <Status status={status} /> || '-',
      hasBorder: true,
      hintText: status === WIRE_STATUS_DB.PENDING && 'This wire has been submitted to the WireVault blockchain.',
    },
    wireAmount: {
      label: 'Wire Amount',
      value: formatAmount(amount) || '-',
      hasBorder: true,
      valueVariant: 'bold',
    },
    wireID: {
      label: 'Wire ID',
      value: ref_id || '-',
    },
    wireCreationDate: {
      label: 'Wire Creation Date',
      value: dateFormatter(date_created) || '-',
    },
    expectedPaymentDate: {
      label: 'Expected Payment Date',
      value: dateFormatter(expected_payment_date) || '-',
    },
    createdBy: {
      label: 'Created by',
      value: created_by?.email || '-',
    },
    lastUpdate: {
      label: 'Last Update',
      value: dateFormatter(date_modified) || '-',
    },
    replacementOf: {
      type: 'component',
      label: `${child_wire ? 'Replaced by' : 'Replacement of'}`,
      isRowHidden: isExecutorRole || !Boolean(child_wire?.id || parent_wire?.id),
      component: () => {
        const wireId = child_wire ? child_wire?.id : parent_wire?.id;

        if (!wireId) return null;

        const url = isOrgAdminRole
          ? getOutboundWireDetailsUrl(transactionId, wireId)
          : getRecipientWireDetailsUrl(wireId);

        return <NavigationLink to={url}>{`${child_wire ? child_wire?.name : parent_wire?.name} `}</NavigationLink>;
      },
    },
    notes: {
      type: 'notes',
      value: notes,
    },
  };
};
