import { useEffect, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthRoutes } from './Auth/routes/AuthRoutes';
import { getContactUsPath } from './pages/ContactUsPage/urls/getContactUsUrl';
import { ContactUsPage } from './pages/ContactUsPage/ContactUsPage';
import { PrivateRoute } from './Routes/PrivateRoute';
import { envConfig } from '@/config/envConfig';
import { USER_ROLE_MAP } from '@/const/user';
import { ProtectedRoute } from './Routes/ProtectedRoute';
import { PRIVATE_ROUTES } from './Routes/privateRoutes';
import { UserProvider } from '@/context/userContext';
import { AccessDeniedPage } from './pages/AccessDeniedPage/AccessDeniedPage';
import { getAccessDeniedPath } from '@/bundle/pages/AccessDeniedPage/urls/getAccessDeniedUrl';
import { useOnline } from '@/hooks/useOnline';
import { removeSnackbarById, showSnackbar } from '@/components/Snackbar/Snackbar';
import { getSwitchRolePath } from '@/bundle/pages/SwitchRolePage/url/getSwitchRoleUrl';
import { SwitchRolePage } from '@/bundle/pages/SwitchRolePage/SwitchRolePage';
import { ContactUsProtectedRoute } from '@/bundle/pages/ContactUsPage/ContactUsProtectedRoute';
import { ProfilePage } from '@/bundle/pages/ProfilePage/ProfilePage';
import { getProfilePath } from '@/bundle/pages/ProfilePage/url/getProfileUrl';
import { ProfileProtectedRoute } from '@/bundle/pages/ProfilePage/ProfileProtectedRoute';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';

export const App = () => {
  const onlineRef = useRef(null);
  const isOnline = useOnline();

  const logBuildVersions = () => {
    // eslint-disable-next-line no-console
    console.log(
      `============< [Release version: ${envConfig.RELEASE_VERSION}], [Build version: ${envConfig.BUILD_VERSION}] >============`
    );
  };

  useEffect(() => {
    logBuildVersions();
  }, []);

  useEffect(() => {
    if (!isOnline) {
      onlineRef.current = showSnackbar('Application is currently offline.', { type: 'error', autoClose: false });

      return;
    }

    if (onlineRef.current) {
      removeSnackbarById(onlineRef.current);
    }
  }, [isOnline]);

  return (
    <Routes>
      {AuthRoutes}
      {/* Private routes that requires authentication */}
      <Route element={<UserProvider />}>
        {/* Sidebar app pages */}
        <Route element={<PrivateRoute />}>
          <Route element={<ContactUsProtectedRoute />}>
            <Route path={getContactUsPath()} element={<ContactUsPage />} />
          </Route>
          <Route element={<ProfileProtectedRoute />}>
            <Route path={getProfilePath()} element={<ProfilePage />} />
          </Route>

          <Route element={<ProtectedRoute allowedRole={USER_ROLE_MAP.OPCO} />}>
            {PRIVATE_ROUTES[USER_ROLE_MAP.OPCO]}
          </Route>
          <Route element={<ProtectedRoute allowedRole={USER_ROLE_MAP.ORGANIZATION} />}>
            {PRIVATE_ROUTES[USER_ROLE_MAP.ORGANIZATION]}
          </Route>
          <Route element={<ProtectedRoute allowedRole={USER_ROLE_MAP.DEPOSITOR} />}>
            {PRIVATE_ROUTES[USER_ROLE_MAP.DEPOSITOR]}
          </Route>
          <Route element={<ProtectedRoute allowedRole={USER_ROLE_MAP.EXECUTOR} />}>
            {PRIVATE_ROUTES[USER_ROLE_MAP.EXECUTOR]}
          </Route>
          <Route element={<ProtectedRoute allowedRole={USER_ROLE_MAP.RECIPIENT} />}>
            {PRIVATE_ROUTES[USER_ROLE_MAP.RECIPIENT]}
          </Route>
        </Route>

        {/* Without sidebar app pages */}
        <Route element={<PrivateRoute renderLayout={false} />}>
          <Route path={getSwitchRolePath()} element={<SwitchRolePage />} />
        </Route>
        <Route path={getAccessDeniedPath()} element={<AccessDeniedPage />} />
      </Route>

      <Route path='*' element={<NotFoundPage />} />
    </Routes>
  );
};
