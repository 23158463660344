import { RadioField } from '../RadioGroup/RadioField';
import { TransactionsFiltersRow, TransactionsFiltersWrapper } from './styles';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { TransactionsSearchCheckbox } from './ui/TransactionsSearchCheckbox';
import {
  initSearchFilters,
  SEARCH_EMAIL_TYPE_OPTIONS,
  SEARCH_EMAIL_TYPES_LIST,
  SEARCH_FILTER_RADIO_OPTION_TYPE,
  SearchEmailType,
  SearchFiltersType,
} from './const';
import { ValueOf } from '@/types/sharedTypes';

export type SearchFilterRadioOptionType = ValueOf<typeof SEARCH_FILTER_RADIO_OPTION_TYPE>;

export type TransactionsFiltersType = {
  searchFilters: SearchFiltersType;
  onChangeSearchFilters: (searchFilters: SearchFiltersType, radioOptionType?: SearchFilterRadioOptionType) => void;
  nameLabel?: string;
};

export const TransactionsSearchFilters = ({
  nameLabel = 'Transaction Name',
  searchFilters,
  onChangeSearchFilters,
}: TransactionsFiltersType) => {
  const { byName } = searchFilters;
  const byEmailType = !byName;

  const changeEmailSearchType = (type: SearchEmailType, checked: boolean) => {
    let searchEmailTypes = [...searchFilters.searchEmailTypes];

    if (checked) {
      searchEmailTypes.push(type);
    } else {
      searchEmailTypes = searchEmailTypes.filter((item) => item !== type);
    }

    onChangeSearchFilters({ ...searchFilters, searchEmailTypes });
  };

  return (
    <TransactionsFiltersWrapper>
      <Typography color={COLORS.black} fontWeigt='semibold' variant='caption' m={['8px', '16px']}>
        SEARCH BY
      </Typography>
      <TransactionsFiltersRow checked={byName}>
        <RadioField
          checked={byName}
          onChange={() =>
            onChangeSearchFilters(
              { ...initSearchFilters, type: searchFilters?.type },
              SEARCH_FILTER_RADIO_OPTION_TYPE.NAME
            )
          }
          label={nameLabel}
        />
      </TransactionsFiltersRow>
      <TransactionsFiltersRow checked={byEmailType}>
        <RadioField
          checked={byEmailType}
          onChange={() =>
            onChangeSearchFilters(
              {
                byName: false,
                searchEmailTypes: SEARCH_EMAIL_TYPES_LIST,
                type: searchFilters?.type,
              },
              SEARCH_FILTER_RADIO_OPTION_TYPE.EMAIL
            )
          }
          label='Email Address'
        />
      </TransactionsFiltersRow>

      {byEmailType && (
        <>
          <TransactionsFiltersRow>
            <Typography variant='caption' fontWeigt='medium' color={COLORS.grey[700]}>
              USER TYPE
            </Typography>
          </TransactionsFiltersRow>

          {SEARCH_EMAIL_TYPE_OPTIONS.map(({ type, label }) => {
            const checked = searchFilters.searchEmailTypes.includes(type);
            const isLastChecked =
              searchFilters.searchEmailTypes.length === 1 && searchFilters.searchEmailTypes.includes(type);

            return (
              <TransactionsSearchCheckbox
                key={type}
                label={label}
                checked={checked}
                isLastChecked={isLastChecked}
                onChange={(value) => changeEmailSearchType(type, value)}
              />
            );
          })}
        </>
      )}
    </TransactionsFiltersWrapper>
  );
};
