import { ReactNode } from 'react';
import { TypographyContainer } from './styles';
import { TypographyVariantType, TypographyFontWeightType } from '@/styles/typography';
import { BoxWrapperPropsType } from '../Box/styles';
import { ColorType } from '@/styles/colors';

type TypographyProps = BoxWrapperPropsType & {
  as?: keyof HTMLElementTagNameMap;
  variant?: TypographyVariantType;
  fontWeigt?: TypographyFontWeightType;
  children: ReactNode;
  color?: ColorType;
};

export const Typography = ({
  as = 'div',
  variant = 'body',
  fontWeigt = 'regular',
  children,
  ...props
}: TypographyProps) => {
  return (
    <TypographyContainer as={as} variant={variant} fontWeight={fontWeigt} {...props}>
      {children}
    </TypographyContainer>
  );
};
