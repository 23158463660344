import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  LogoutLink,
  LogoutLinkWrapper,
  LogoutWrapper,
  ProfileSectionTitle,
  SidebarFooter,
  SidebarFooterWrapper,
  UserEmail,
  UserInfo,
  UserType,
} from './styles';
import { Tooltip } from '@/components/Tooltip/Tooltip';
import { ReactComponent as LogoutIcon } from '../../images/LogoutIcon.svg';
import { amplitudeService } from '@/services/amplitudeService/amplitudeService';
import { getLoginUrl } from '@/bundle/Auth/LoginFlow/LoginPage/urls/getLoginUrl';
import { USER_ROLE_MAP } from '@/const/user';
import { authTokenService } from '@/bundle/Auth/LoginFlow/LoginPage/service/authTokenService';
import { logout } from '../../api';
import { useUser } from '@/context/userContext';
import { Box } from '@/components/Box/Box';
import { SwitchRoleButton } from '@/bundle/Sidebar/ui/SwitchRoleButton/SwitchRoleButton';
import { hasMultipleRoles } from '@/helpers/userHelpers';
import { sessionStorageService } from '@/helpers/storageHelpers';
import { CURRENT_ROLE_KEY } from '@/const/shared';
import { MenuSection, MenuSectionIcon, MenuSectionLink } from '@/bundle/Sidebar/ui/SidebarMenu/styles';
import { ReactComponent as ProfileIcon } from '../../../../images/ProfileIcon.svg';
import { isActiveMenu } from '@/bundle/Sidebar/ui/SidebarMenu/SidebarMenu';
import { getProfilePath } from '@/bundle/pages/ProfilePage/url/getProfileUrl';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import {
  getOpcoUserDetailsUrl,
  getOpcoUserDetailsPath,
} from '@/bundle/_Opco/_OpcoUsersPage/Details/url/getOpcoUserDetailsUrl';
import { removeAllSnackbars } from '@/components/Snackbar/Snackbar';

export const USER_ROLE_LABEL_MAP = {
  [USER_ROLE_MAP.ORGANIZATION]: 'Org Admin',
  [USER_ROLE_MAP.OPCO]: 'OpCo',
  [USER_ROLE_MAP.RECIPIENT]: 'Recipient',
  [USER_ROLE_MAP.EXECUTOR]: 'Executor',
  [USER_ROLE_MAP.DEPOSITOR]: 'Depositor',
};

interface SidebarUserDetailsType {
  onNavigate: () => void;
}

export const SidebarUserDetails = ({ onNavigate }: SidebarUserDetailsType) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const { user, currentRole, isOpcoRole } = useUser();

  const { mutate } = useMutation({
    mutationKey: ['logout'],
    mutationFn: () => {
      return logout();
    },
    onSuccess(logoutResponse) {
      if (logoutResponse?.error) return;

      amplitudeService.resetUser();
      authTokenService.removeTokens();
      queryClient.removeQueries();
      sessionStorageService.remove(CURRENT_ROLE_KEY);
      removeAllSnackbars();

      navigate(getLoginUrl());
    },
  });

  const handleLogout = () => {
    mutate();
  };

  const email = user?.email;
  const userRole = USER_ROLE_LABEL_MAP[currentRole];
  const showSwitchRoles = hasMultipleRoles(user);
  const currentPath = getProfilePath();
  const isActive = isActiveMenu(pathname, [currentPath]);
  // Since Opco users has different profile url - add additional conditions for them
  const isActiveProfile =
    isActive || (isOpcoRole && isActiveMenu(pathname, [getOpcoUserDetailsPath()]) && user.id === id);
  const currentUrl = isOpcoRole ? getOpcoUserDetailsUrl(user?.id) : getProfilePath();

  const navigateToProfile = () => {
    if (currentUrl !== pathname) {
      amplitudeService.logEvent(AMPLITUDE_EVENTS.ProfileStartRedirect);
    }

    onNavigate();
  };

  return (
    <SidebarFooterWrapper>
      <SidebarFooter>
        <Box flexDirection='column' rowGap='20px' width='100%'>
          <UserInfo>
            <UserEmail data-tooltip-id='email-tooltip'>{email}</UserEmail>
            <Tooltip id='email-tooltip' place='top'>
              {email}
            </Tooltip>
            {userRole && <UserType>{userRole}</UserType>}
          </UserInfo>
          {showSwitchRoles && <SwitchRoleButton role={currentRole} />}
        </Box>
      </SidebarFooter>

      <LogoutWrapper>
        <MenuSection isActive={isActiveProfile}>
          <MenuSectionLink to={currentUrl} onClick={navigateToProfile}>
            <MenuSectionIcon>
              <ProfileIcon />
            </MenuSectionIcon>
            <ProfileSectionTitle isActive={isActiveProfile}>Profile</ProfileSectionTitle>
          </MenuSectionLink>
        </MenuSection>
        <LogoutLinkWrapper onClick={handleLogout}>
          <LogoutIcon />
          <LogoutLink>Log out</LogoutLink>
        </LogoutLinkWrapper>
      </LogoutWrapper>
    </SidebarFooterWrapper>
  );
};
