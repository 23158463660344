import { WireInboundTypeIconComponent, WireOutboundTypeIconComponent } from '../ui/WireTypeIconComponent';
import { AMPLITUDE_EVENTS } from '@/services/amplitudeService/amplitudeEvents';
import { WIRE_TYPE_PLURAL_LABEL } from '@/const/wire';

export const WIRE_TYPE_ALL = 'all';
export const WIRE_TYPE_INBOUND = 'inbound';
export const WIRE_TYPE_OUTBOUND = 'outbound';
export type WireTypesType = typeof WIRE_TYPE_ALL | typeof WIRE_TYPE_INBOUND | typeof WIRE_TYPE_OUTBOUND;
export type WireFilterType = typeof WIRE_TYPE_INBOUND | typeof WIRE_TYPE_OUTBOUND;

export const SEARCH_WIRE_TYPES_LIST: WireFilterType[] = [WIRE_TYPE_INBOUND, WIRE_TYPE_OUTBOUND];

export const WIRE_TYPE_FILTER_CONFIG = [
  {
    id: 1,
    title: 'All',
    value: WIRE_TYPE_ALL,
  },
  {
    id: 2,
    title: WIRE_TYPE_PLURAL_LABEL.inbound,
    value: WIRE_TYPE_INBOUND,
    icon: <WireInboundTypeIconComponent />,
    iconPlacement: 'before',
  },
  {
    id: 3,
    title: WIRE_TYPE_PLURAL_LABEL.outbound,
    value: WIRE_TYPE_OUTBOUND,
    icon: <WireOutboundTypeIconComponent />,
    iconPlacement: 'after',
  },
];

export const WIRE_TYPE_FILTER_AMPLITUDE_EVENTS_MAP = {
  [WIRE_TYPE_ALL]: AMPLITUDE_EVENTS.ClickWireFilterAll,
  [WIRE_TYPE_OUTBOUND]: AMPLITUDE_EVENTS.ClickWireFilterOutbound,
  [WIRE_TYPE_INBOUND]: AMPLITUDE_EVENTS.ClickWireFilterInbound,
};
