import { envConfig } from '@/config/envConfig';
import { FilterParamsType, QueryParamsType } from './queryHelpers';

type DefaultListParamsType = {
  limit: number;
  offset: number;
};

export interface ApiParams extends FilterParamsType {
  limit?: number;
  offset?: number;
  ordering?: string;
  search?: string;
}

type URLSearchParams = Record<string, string>;

export const prepareOffset = (queryParams: QueryParamsType, defaultListParams: DefaultListParamsType) => {
  if (queryParams.page) {
    return queryParams.limit ? queryParams.limit * queryParams.page : defaultListParams.limit * queryParams.page;
  }

  return defaultListParams.offset;
};

export const urlApiHelper = (uri: string, params?: ApiParams) => {
  let finalUrl = `${envConfig.API_URL}${uri}`;

  if (params) {
    const searchParamsString = new URLSearchParams(params as URLSearchParams).toString().replace('%2C', ',');

    finalUrl = `${finalUrl}?${searchParamsString}`;
  }

  return finalUrl;
};

export const defaultListParams: DefaultListParamsType = {
  limit: 15,
  offset: 0,
};

export const prepareBaseListApiParams = (queryParams: QueryParamsType) => {
  const params: ApiParams = {};

  params.limit = queryParams.limit ? queryParams.limit : defaultListParams.limit;
  params.offset = prepareOffset(queryParams, defaultListParams);

  if (queryParams.ordering) {
    params.ordering = queryParams.ordering;
  }

  return params;
};
