import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';
import { getCurrencyApi } from '@/api/v1/reference-books/getCurrencyApi';
import { createWireApi } from '@/api/v1/organization/contracts/createWireApi';
import { getTransactionsWiresApi } from '@/api/v1/organization/contracts/getTransactionsWiresApi';
import { getTransactionApi } from '@/api/v1/organization/contracts/getTransactionApi';
import { updateTransactionApi } from '@/api/v1/organization/contracts/updateTransactionApi';
import { deleteTransactionApi } from '@/api/v1/organization/contracts/deleteTransactionApi';
import { prepareSearchFiltersListApiParams } from '../../api/helpers';

export const loadCurrency = async () => {
  try {
    const response = await getCurrencyApi();

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadTransactionWires = async (id: string, params) => {
  const apiParams = prepareSearchFiltersListApiParams(params);

  try {
    const response = await getTransactionsWiresApi(id, apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const createWire = async (id: string, payload) => {
  try {
    const response = await createWireApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const loadTransactionDetails = async (id: string) => {
  try {
    const response = await getTransactionApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const updateTransactionDetails = async (id: string, payload) => {
  try {
    const response = await updateTransactionApi(id, payload);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};

export const deleteTransaction = async (id: string) => {
  try {
    const response = await deleteTransactionApi(id);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
