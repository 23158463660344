import { ReactNode } from 'react';
import { ContentHeader, ContentHeaderType } from './ContentHeader/ContentHeader';
import { ContentLayoutWrapper, ContentWrapper, BannerWrapper } from './styles';

type ContentLayoutType = {
  children?: ReactNode;
  banner?: ReactNode;
} & ContentHeaderType;

export const ContentLayout = (props: ContentLayoutType) => {
  const { children, banner, ...rest } = props;

  return (
    <>
      {banner && <BannerWrapper>{banner}</BannerWrapper>}

      <ContentLayoutWrapper>
        <ContentHeader {...rest} />
        <ContentWrapper>{children}</ContentWrapper>
      </ContentLayoutWrapper>
    </>
  );
};
