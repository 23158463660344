import { ClipboardEvent, ComponentPropsWithoutRef, ReactNode } from 'react';
import { Box } from '@/components/Box/Box';
import { CustomInput, BeforeAfterWrapper, IconSearchWrapper, InputSizeVariant, InputWrapper } from './styles';
import { Typography } from '@/components/Typography/Typography';
import { COLORS } from '@/styles/colors';
import { InputError } from '../InputError/InputError';
import { ReactComponent as ClearIcon } from './ClearIcon.svg';
import { ReactComponent as ConfirmInputTicks } from '../../../../images/ConfirmInputTicks.svg';
import { Tooltip } from '@/components/Tooltip/Tooltip';

export type InputType = {
  label?: string;
  variant?: InputSizeVariant;
  labelHint?: ReactNode;
  before?: ReactNode;
  after?: ReactNode;
  hasConfirmTicks?: boolean;
  hasErrorComponent?: boolean;
  error?: string;
  apiError?: string;
  children?: ReactNode;
  hasToggleEye?: boolean;
  isCopyPasteDisabled?: boolean;
  onClear?: () => void;
} & ComponentPropsWithoutRef<'input'>;

export const Input = ({
  label,
  variant = 'medium',
  labelHint,
  before,
  after,
  hasConfirmTicks,
  hasErrorComponent = true,
  error,
  apiError,
  isCopyPasteDisabled,
  onClear,
  disabled,
  ...rest
}: InputType) => {
  const hasError = !!error || !!apiError;

  const hasClearButton = rest.value && !!onClear;

  const onCopyPasteHandler = (event: ClipboardEvent<HTMLInputElement>) => {
    if (isCopyPasteDisabled) {
      event.preventDefault();
    }
  };

  return (
    <Box width='100%'>
      <Box display='flex' alignItems='center' justifyContent='space-between' columnGap='10px'>
        {label && (
          <Typography variant='bodySmall' color={COLORS.black} mb='4px'>
            {label}
          </Typography>
        )}
        {labelHint && (
          <Typography variant='caption' color={COLORS.grey[700]} fontWeigt='semibold' mb='4px'>
            {labelHint}
          </Typography>
        )}
      </Box>
      <InputWrapper variant={variant} disabled={disabled} hasError={hasError}>
        {!!before && <BeforeAfterWrapper>{before}</BeforeAfterWrapper>}
        <CustomInput
          variant={variant}
          onPaste={onCopyPasteHandler}
          onCopy={onCopyPasteHandler}
          disabled={disabled}
          {...rest}
        />
        {hasClearButton && (
          <IconSearchWrapper data-tooltip-id='clear-search-tooltip' onClick={onClear}>
            <Tooltip type='hint' id='clear-search-tooltip'>
              Clear search
            </Tooltip>
            {<ClearIcon />}
          </IconSearchWrapper>
        )}
        {!!after && <BeforeAfterWrapper>{after}</BeforeAfterWrapper>}
        {hasConfirmTicks && <ConfirmInputTicks />}
      </InputWrapper>
      {hasErrorComponent && (
        <Box display='flex'>
          <InputError error={error} apiError={apiError} />
        </Box>
      )}
    </Box>
  );
};
