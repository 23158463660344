import { ItemWrapperWithIcon, ItemWrapperWithOutIcon, WireTypeFilterWrapper } from './styles';
import { ReactNode } from 'react';

type WireTypeFilterType = {
  selected: string;
  onSelect: (value: string) => void;
  config: {
    id: number;
    title: string;
    value: string;
    icon?: ReactNode;
    iconPlacement?: string;
  }[];
};

export const WireTypeFilter = ({ selected, onSelect, config }: WireTypeFilterType) => {
  return (
    <WireTypeFilterWrapper>
      {config.map(({ id, icon, value, title, iconPlacement }) => {
        const CustomWrapper = icon ? ItemWrapperWithIcon : ItemWrapperWithOutIcon;
        const isActiveTab = selected === value;
        const hasLeftIcon = icon && iconPlacement === 'before';
        const hasRightIcon = icon && iconPlacement === 'after';

        return (
          <CustomWrapper key={id} active={isActiveTab} onClick={() => onSelect(value)} iconPosition={iconPlacement}>
            {hasLeftIcon && <>{icon}</>}
            <div>{title}</div>
            {hasRightIcon && <>{icon}</>}
          </CustomWrapper>
        );
      })}
    </WireTypeFilterWrapper>
  );
};
