import { createPortal } from 'react-dom';
import { ITooltip as TooltipType } from 'react-tooltip';
import { StyledTooltip } from './styles';

export type TooltipPropsType = {
  type?: 'tooltip' | 'hint';
} & TooltipType;

export const TOOLTIP_HINT_DELAY = 600;

export const Tooltip = ({ type = 'tooltip', ...props }: TooltipPropsType) => {
  const delay = type === 'hint' ? TOOLTIP_HINT_DELAY : undefined;

  return createPortal(
    <StyledTooltip noArrow type={type} delayShow={delay} {...props} />,
    document.getElementById('tooltip')
  );
};
