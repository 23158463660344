import { useGetSearchParams } from '@/hooks/useGetSearchParams';
import { useReplaceSearchParams } from '@/hooks/useReplaceSearchParams';
import { useEffect, useState } from 'react';

export type SearchParamsType = {
  name?: string;
};

export const useSearch = () => {
  const searchParams = useGetSearchParams<SearchParamsType>();
  const { replaceSearchParams } = useReplaceSearchParams<SearchParamsType>();

  const [search, setSearch] = useState('');

  useEffect(() => {
    const name = searchParams?.name || '';

    setSearch(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.name]);

  const clearSearch = () => {
    setSearch('');
    replaceSearchParams({ name: '', page: null });
  };

  const submitSearch = () => {
    if (!search?.length) {
      return replaceSearchParams({ name: '', page: null });
    }

    replaceSearchParams({ name: search, page: null });
  };

  return {
    search,
    setSearch,
    clearSearch,
    submitSearch,
    searchParams,
  };
};
