import { getDepositorWiresApi } from '@/api/v1/depositor/getDepositorWiresApi';
import { prepareSearchNameListApiParams } from '@/bundle/shared/helpers/apiParamsHelpers';
import { prepareResponse, throwResponseError } from '@/helpers/apiHelpers/responseHelpers';

export const loadDepositorWires = async (queryParams) => {
  const apiParams = prepareSearchNameListApiParams(queryParams);

  try {
    const response = await getDepositorWiresApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
