import { prepareBaseListApiParams } from '@/api/helpers/urlApiHelpers';
import { FiltersSearchParams } from '@/bundle/_OrgAdmin/hooks/useFilterSearch';
import { SEARCH_EMAIL_TYPES_LIST } from '@/bundle/_OrgAdmin/ui/TransactionsSearchFilters/const';
import { SearchParamsType } from '@/bundle/shared/hooks/useSearch';
import { QueryParamsType } from '@/hooks/useGetSearchParams';
import { SEARCH_WIRE_TYPES_LIST } from '../_Details/const/const';

type QueryParams = QueryParamsType & SearchParamsType & FiltersSearchParams;

export const prepareSearchFiltersListApiParams = (queryParams: QueryParams) => {
  const baseParams = prepareBaseListApiParams(queryParams);

  let params = { ...baseParams };

  if (queryParams.name && !queryParams.types) {
    params.name = queryParams.name;
  }

  if (queryParams.name && queryParams.types) {
    const filteredTypes = queryParams.types.filter((type) =>
      SEARCH_EMAIL_TYPES_LIST.some((item) => item.toLowerCase() === type.toLowerCase())
    );

    const emailTypes = filteredTypes.reduce((result, current) => {
      result[current] = queryParams.name;

      return result;
    }, {});

    params = {
      ...params,
      ...emailTypes,
    };
  }

  if (queryParams.type) {
    const existingWireType = SEARCH_WIRE_TYPES_LIST.find(
      (wireType) => wireType.toLowerCase() === queryParams.type.toLowerCase()
    );

    if (existingWireType) {
      params.type = queryParams.type;
    }
  }

  return params;
};
