import { defaultFilterParams, FilterParamsType, QueryParamsType } from '@/api/helpers/queryHelpers';
import { ApiParams, prepareBaseListApiParams } from '@/api/helpers/urlApiHelpers';

export const getBilledEventsList = (billedEvents = []) => {
  return billedEvents.map(({ id, date_created, wire, user, organization, id_verification }) => {
    return {
      id: id,
      org_name: organization?.name,
      date_viewed: date_created,
      wire_name: wire?.name,
      id_verification,
      wire_ref_id: wire?.ref_id,
      user_viewed: user?.email,
      transaction_name: wire?.contract?.name,
      payment_details_collection_type: wire?.payment_details_collection_type,
    };
  });
};

export const getOrganizationOptions = (organizations = []) => {
  return organizations.map((organization) => {
    return {
      value: organization?.id,
      label: organization?.name,
    };
  });
};

export const prepareBillingSearchFiltersListApiParams = (queryParams?: QueryParamsType): ApiParams => {
  const baseParams = prepareBaseListApiParams(queryParams);

  const params = { ...baseParams };

  if (queryParams.filters) {
    const filterValues: FilterParamsType = queryParams.filters;
    const filterNames = Object.keys(defaultFilterParams) as (keyof FilterParamsType)[];

    filterNames.forEach((filterName) => {
      const value = filterValues[filterName];

      if (value) {
        // @ts-ignore
        params[filterName] = Array.isArray(value) ? value.map((item) => item?.value || item).join(',') : value;
      }
    });
  }

  if (queryParams.search) {
    params.search = queryParams.search;
  }

  return params;
};
