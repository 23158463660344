import { QueryParamsType } from '@/api/helpers/queryHelpers';
import { prepareBaseListApiParams } from '@/api/helpers/urlApiHelpers';

export const prepareSearchNameListApiParams = (queryParams: QueryParamsType) => {
  const baseParams = prepareBaseListApiParams(queryParams);

  const params = {
    ...baseParams,
  };

  if (queryParams?.name) {
    params.name = queryParams.name;
  }

  return params;
};
