import { ErrorHint } from '@/components/Widget/ui/ErrorHint/ErrorHint';
import { Status } from '../../../../../components/Status/Status';
import { ReactComponent as CalendarIcon } from '../../../../../images/CalendarIcon.svg';
import { dateFormatter, formatBlockchainId, phoneFormatter } from '@/helpers/formatHelpers';
import { GRADIENTS } from '@/styles/gradients';
import { WIRE_STATUS_DB, WIRE_TYPE_SINGULAR_LABEL } from '@/const/wire';
import { FormConfigType } from '@/helpers/formHelpers/formHelpers';
import {
  AMOUNT_VALIDATOR,
  REQUIRED_OBJECT_VALIDATOR,
  REQUIRED_TEXT_FIELD_VALIDATOR,
  STRING_VALIDATOR,
  TEXT_FIELD_VALIDATOR,
} from '@/helpers/formHelpers/validators';
import { DetailsEditButton } from '@/bundle/_OrgAdmin/shared/ui/DetailsEditButton/DetailsEditButton';

export const inboundWireDetailsInfo = (wire: any, hasEditButton: boolean, formatAmount: (number) => string) => {
  return {
    transactionName: {
      label: 'Transaction Name',
      value: wire?.contract?.name || '-',
    },
    wireName: {
      type: 'component',
      label: 'Wire Name',
      value: wire?.name || '-',
      valueVariant: 'big',
      component: hasEditButton ? ({ onClick }: any) => <DetailsEditButton onEdit={onClick} /> : null,
    },
    errorHint: {
      type: 'hint',
      label: 'Wire failed status',
      component: wire?.status === WIRE_STATUS_DB.FAILED ? () => <ErrorHint /> : null,
    },
    type: {
      label: 'Type',
      value: WIRE_TYPE_SINGULAR_LABEL[wire?.type],
    },
    status: {
      type: 'component',
      label: 'Status',
      component: () => <Status status={wire?.status} /> || '-',
      hasBorder: true,
      hintText: wire?.status === WIRE_STATUS_DB.PENDING && 'This wire has been submitted to the WireVault blockchain.',
    },
    wireAmount: {
      label: 'Wire Amount',
      value: formatAmount(wire?.amount) || '-',
      hasBorder: true,
      valueVariant: 'bold',
    },
    wireID: {
      label: 'Wire ID',
      value: wire?.ref_id || '-',
    },
    wireCreationDate: {
      label: 'Wire Creation Date',
      value: dateFormatter(wire?.date_created) || '-',
    },
    expectedPaymentDate: {
      label: 'Expected Payment Date',
      value: dateFormatter(wire?.expected_payment_date) || '-',
    },
    createdBy: {
      label: 'Created by',
      value: wire?.created_by?.email || '-',
    },
    lastUpdate: {
      label: 'Last Update',
      value: dateFormatter(wire?.date_modified) || '-',
    },
    notes: {
      type: 'notes',
      value: wire?.notes,
    },
  };
};

export const getInboundWireEditConfig = (currentOptions = []): FormConfigType => {
  return [
    {
      name: 'name',
      label: 'Wire Name*',
      placeholder: 'Enter Name',
      validator: REQUIRED_TEXT_FIELD_VALIDATOR,
    },
    {
      name: 'ref_id',
      label: 'Wire ID',
      placeholder: 'Enter Wire ID',
      disabled: true,
    },
    {
      name: 'type',
      label: 'Wire Type',
      disabled: true,
    },
    {
      name: 'date_created',
      label: 'Wire Creation Date',
      disabled: true,
      column: 6,
      meta: {
        iconRight: <CalendarIcon />,
      },
    },
    {
      name: 'expected_payment_date',
      label: 'Expected Payment Date',
      type: 'datepicker',
      placeholder: 'Select Date',
      column: 6,
      validator: STRING_VALIDATOR,
      meta: {
        hasErrorComponent: true,
      },
    },
    {
      name: 'amount',
      label: 'Wire Amount',
      placeholder: 'Enter Number',
      column: 6,
      validator: AMOUNT_VALIDATOR,
    },
    {
      name: 'currency',
      label: 'Currency',
      type: 'select',
      options: currentOptions,
      column: 6,
      validator: REQUIRED_OBJECT_VALIDATOR,
    },
    {
      name: 'notes',
      label: 'Notes',
      type: 'textarea',
      placeholder: 'Enter Notes',
      validator: TEXT_FIELD_VALIDATOR,
    },
  ];
};

export const depositorEditFormConfiguration = () => {
  return {
    email: {
      title: 'Email Address*',
      disabled: true,
    },
    mobile_phone: {
      type: 'phone_input',
      title: 'Mobile Phone Number*',
      placeholder: 'Enter Mobile Phone Number With Country Code',
    },
    first_name: {
      title: 'First Name',
      placeholder: 'Enter First Name',
    },
    middle_name: {
      title: 'Middle Name',
      placeholder: 'Enter Middle Name',
    },
    last_name: {
      title: 'Last Name',
      placeholder: 'Enter Last Name',
    },
  };
};

export const paymentDetailsDomesticWidgetConfig = (paymentDetailsItem) => {
  return {
    blockchainId: {
      label: 'Blockchain ID',
      value: formatBlockchainId(paymentDetailsItem?.blockchain_id),
      title: paymentDetailsItem?.blockchain_id || '-',
    },
    recipient: {
      label: 'Recipient',
      value: paymentDetailsItem?.internal_payment_details_data?.recipient_name || '-',
    },
    recipient_address: {
      label: 'Recipient Address',
      value: paymentDetailsItem.internal_payment_details_data?.recipient_address || '-',
    },
    accountName: {
      label: 'Account Name',
      value: paymentDetailsItem?.internal_payment_details_data?.account_name || '-',
    },
    abaNumber: {
      label: 'Routing / ABA Number',
      value: paymentDetailsItem?.blockchain_payment_details_data?.bank_aba_number || '-',
      gradient: paymentDetailsItem?.is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    bankName: {
      label: 'Bank Name',
      value: paymentDetailsItem?.internal_payment_details_data?.bank_name || '-',
    },
    accountNumber: {
      label: 'Account Number',
      value: paymentDetailsItem?.blockchain_payment_details_data?.bank_account_number || '-',
      gradient: paymentDetailsItem?.is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    bankAddress: {
      label: 'Bank Address',
      value: paymentDetailsItem?.internal_payment_details_data?.bank_address || '-',
    },
    bankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(paymentDetailsItem?.internal_payment_details_data?.bank_phone_number),
    },
  };
};

export const paymentDetailsInternationalWidgetConfig = (paymentDetailsItem) => {
  return {
    blockchainId: {
      label: 'Blockchain ID',
      value: formatBlockchainId(paymentDetailsItem?.blockchain_id),
      title: paymentDetailsItem?.blockchain_id || '-',
    },
    recipient: {
      label: 'Recipient',
      value: paymentDetailsItem?.internal_payment_details_data?.recipient_name || '-',
    },
    recipient_address: {
      label: 'Recipient Address',
      value: paymentDetailsItem.internal_payment_details_data?.recipient_address || '-',
    },
    accountName: {
      label: 'Account Name',
      value: paymentDetailsItem?.internal_payment_details_data?.account_name || '-',
    },
    swiftCode: {
      label: 'Swift Code',
      value: paymentDetailsItem?.blockchain_payment_details_data?.bank_swift_code || '-',
      gradient: paymentDetailsItem?.is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    bankName: {
      label: 'Bank Name',
      value: paymentDetailsItem?.internal_payment_details_data?.bank_name || '-',
    },
    accountNumber: {
      label: 'Account Name',
      value: paymentDetailsItem?.blockchain_payment_details_data?.bank_account_number || '-',
      gradient: paymentDetailsItem?.is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    bankAddress: {
      label: 'Bank Address',
      value: paymentDetailsItem?.internal_payment_details_data?.bank_address || '-',
    },
    bankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(paymentDetailsItem?.internal_payment_details_data?.bank_phone_number),
    },
  };
};

export const domesticIntermediaryBank = (paymentDetails) => {
  return {
    intermediaryBankAbaNumber: {
      label: 'Routing / ABA Number',
      value: paymentDetails?.blockchain_payment_details_data?.intermediary_bank_aba_number || '-',
      gradient: paymentDetails?.is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    intermediaryBankName: {
      label: 'Bank Name',
      value: paymentDetails?.internal_payment_details_data?.intermediary_bank_name || '-',
    },
    intermediaryBankAccountNumber: {
      label: 'Account Number',
      value: paymentDetails?.blockchain_payment_details_data?.intermediary_bank_account_number || '-',
      gradient: paymentDetails?.is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    intermediaryBankAddress: {
      label: 'Bank Address',
      value: paymentDetails?.internal_payment_details_data?.intermediary_bank_address || '-',
    },
    intermediaryBankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(paymentDetails?.internal_payment_details_data?.intermediary_bank_phone_number),
    },
  };
};

export const internationalIntermediaryBank = (paymentDetailsItem) => {
  return {
    intermediaryBankAbaNumber: {
      label: 'Swift Code',
      value: paymentDetailsItem?.blockchain_payment_details_data?.intermediary_bank_swift_code || '-',
      gradient: paymentDetailsItem?.is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    intermediaryBankName: {
      label: 'Bank Name',
      value: paymentDetailsItem?.internal_payment_details_data?.intermediary_bank_name || '-',
    },
    intermediaryBankAccountNumber: {
      label: 'Account Number',
      value: paymentDetailsItem?.blockchain_payment_details_data?.intermediary_bank_account_number || '-',
      gradient: paymentDetailsItem?.is_blockchain_payment_details_data_from_bc ? GRADIENTS.green : '',
    },
    intermediaryBankAddress: {
      label: 'Bank Address',
      value: paymentDetailsItem?.internal_payment_details_data?.intermediary_bank_address || '-',
    },
    intermediaryBankPhoneNumber: {
      label: 'Bank Phone Number',
      value: phoneFormatter(paymentDetailsItem?.internal_payment_details_data?.intermediary_bank_phone_number),
    },
  };
};
