import { COLORS } from '@/styles/colors';
import { flexCenter } from '@/styles/layout';
import { THEME } from '@/styles/theme';
import styled from 'styled-components';

export const SearchInputWrapper = styled.form`
  position: relative;
  width: 100%;
`;

export const IconFiltersWrapper = styled.div<{ selected?: boolean }>`
  width: 44px;
  margin-right: -16px;
  height: 100%;
  ${flexCenter};
  border-left: 1px solid ${COLORS.grey[400]};
  cursor: pointer;
  background: ${COLORS.grey[200]};

  ${({ selected }) =>
    selected &&
    `
      svg {
        color: ${COLORS.black};
      }
  `}

  &:hover {
    background: ${COLORS.grey[300]};
    svg {
      color: ${COLORS.black};
    }
  }
`;

export const FiltersModalWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 280px;
  z-index: ${THEME.modal.zIndex};
  box-shadow: 0 2px 20px rgba(63, 77, 90, 0.25);
  border-radius: 16px;
  background: ${COLORS.white};
  overflow: hidden;
  border: 1px solid ${COLORS.grey[850]};
  transform: translateY(-16px);
`;
