import { getExecutorWiresApi } from '@/api/v1/executor/getExecutorWiresApi';
import { prepareResponse, throwResponseError } from '../../../helpers/apiHelpers/responseHelpers';
import { prepareSearchNameListApiParams } from '@/bundle/shared/helpers/apiParamsHelpers';

export const loadExecutorWires = async (queryParams) => {
  const apiParams = prepareSearchNameListApiParams(queryParams);

  try {
    const response = await getExecutorWiresApi(apiParams);

    return prepareResponse(response);
  } catch (e) {
    throwResponseError(e);
  }
};
