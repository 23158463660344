import { COLORS } from '@/styles/colors';
import { bodySmall, poppinsMediumFont } from '@/styles/typography';
import styled, { css } from 'styled-components';

const GeneralItemStyle = css<{ active?: boolean }>`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${poppinsMediumFont};
  ${bodySmall};
  color: ${COLORS.black};
  cursor: pointer;
  background-color: ${COLORS.grey[200]};
  border-radius: 8px;

  ${({ active }) =>
    active &&
    css`
      background-color: ${COLORS.black};
      color: ${COLORS.white};
    `}
`;

export const WireTypeFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-bottom: 20px;
`;

export const FilterItem = styled.div``;

export const ItemWrapperWithOutIcon = styled.div<{ active?: boolean; iconPosition?: string }>`
  ${GeneralItemStyle};
  padding: 8px 16px;
`;

export const ItemWrapperWithIcon = styled.div<{ active?: boolean; iconPosition?: string }>`
  ${GeneralItemStyle};
  padding: 8px 8px 8px 12px;
  column-gap: 8px;

  ${({ iconPosition }) =>
    iconPosition === 'before' &&
    `
    padding: 8px 12px 8px 8px`}
`;
